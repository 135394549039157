import React from 'react';
import Popup from 'reactjs-popup';
import { navigate } from 'gatsby';
const axios = require('axios').default;
class MailchimpPopup extends React.Component {
	constructor(props) {
		super(props);
		this.label = props.link.label;
		this.link = props.link.link;
	}

	async handleSubmit(e, link) {
		e.preventDefault();
		const emailField = document.querySelector('#popup-subscriber-email');
		const email = emailField.value;
		try {
			await axios.post(
				`https://langi.app/api/preauth/addToHowToVietnamese`,
				{
					params: { email },
				},
				{
					headers: {
						authorization: 'howtovietnamese!',
					},
				}
			);

			// clear the field
			emailField.value = '';
			// name.value = "";
			document.getElementById('closeModal').click();
			// navigate to the course
			navigate(link);
			if (typeof window !== 'undefined') {
				const storage = localStorage.getItem('user');
				let user = storage ? JSON.parse(storage) : null;
				if (user) {
					user.hasBoughtCourse = true;
				} else {
					user = {
						hasBoughtCourse: true,
					};
				}

				localStorage.setItem('user', JSON.stringify(user));
			}
		} catch (error) {
			alert('Something went wrong');
		}
	}

	render() {
		return (
			<Popup
				modal={true}
				trigger={<span> {this.label}</span>}
				arrow={false}
				closeOnDocumentClick={true}
				closeOnEscape={true}
			>
				{(close) => (
					<div className="mailchimp-popup row">
						<a id="closeModal" onClick={close}>
							&times;
						</a>
						<div className="mailchimp-popup-content col-12">
							<h2 className="mailchimp-popup-title mt-1">Ready to learn? </h2>
							<p className="mailchimp-popup-subtitle mb-2">
								One-time signup to access our free courses, receive learning
								tips and updates twice a month!
							</p>
							{/* <p className="mailchimp-popup-subtitle">for all Vietnamese learners out there!</p> */}
							<form onSubmit={(e) => this.handleSubmit(e, this.link)}>
								<div className="mailchimp-popup-input">
									<input
										placeholder="Your email"
										required
										className="form-control"
										type="email"
										name="subscriber-form"
										id="popup-subscriber-email"
									/>
								</div>

								<input
									className="rectangle-cta mailchimp-popup-submit"
									type="submit"
									value="Sign me up!"
								/>
							</form>
							<p className="mailchimp-popup-footer mb-1">
								*We never share your information and you can opt out anytime, we
								promise!
							</p>
						</div>
					</div>
				)}
			</Popup>
		);
	}
}

export default MailchimpPopup;
