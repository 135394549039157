import React from "react";
import Layout from "../layouts/index";
import Link from "gatsby-link";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { ReactPhotoCollage } from "react-photo-collage";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import SEO from "../components/SEO";
import MailchimpPopup from "../components/MailchimpPopup";
import PromotionPopup from "../components/PromotionPopup";

class CourseDetails extends React.Component {
  constructor(props) {
    // Note:
    // This template is used to display detail pages of three type of content: book, audio and course
    // use type to distinguish each of them
    // course is the standard one, does not require extra infos because it creates a TOC from the headings
    // book requires an array of images (previewImages) to display a preview of the book and an array of links where to purchase it, the first one is solid, the other are outlined

    // for course, information are pulled in from the relevant MDX file
    // for book and audios the information come from the shop.json file which is also used to display the cards of the shop item
    super(props);
    this.course =
      props.data.allMdx.edges && props.data.allMdx.edges.length
        ? props.data.allMdx.edges[0].node.frontmatter
        : null;
    if (props.data.allMdx.edges && props.data.allMdx.edges.length) {
      const headings = props.data.allMdx.edges[0].node.headings;
      this.headings = headings.filter((heading) => heading.depth === 2);
    } else {
      this.headings = null;
    }
    // this is null because it points to podia there is no page for this course
    this.shopItem = props.data.allShopJson.edges.length
      ? props.data.allShopJson.edges[0].node
      : null;
    this.extraInfo = props.pageContext.courseDetail.extraInfo;
    this.price = props.pageContext.courseDetail.price;
    // type : course | audio | book
    this.type = props.pageContext.courseDetail.type;

    if (this.type === "book") {
      this.setting = {
        width: "100%",
        height: ["500px", "170px"],
        layout: [1, 3],
        photos: props.pageContext.courseDetail.previewImages.map((image) => {
          return { src: image };
        }),
      };
    }

    if (this.type !== "course") {
      this.imageContainerClass =
        "col-12 col-md-3 d-flex justify-content-center image-md mb-2 mb-md-0";
    } else {
      this.imageContainerClass = "col-12 col-md-4 mb-2 mb-md-0";
    }

    this.purchaseLinks = props.pageContext.courseDetail.purchaseLinks || [];
    this.excerpt = this.course
      ? this.course.excerpt
      : this.shopItem && this.shopItem.description;
    this.title = this.course
      ? this.course.title
      : this.shopItem && this.shopItem.title;
    this.url = `https://howtovietnamese.com${props.pageContext.detailPagePath}`;
  }

  buttonForCourse(link) {
    if (this.isOutbound) {
      return (
        <OutboundLink target="_blank" href={link.link}>
          {link.label}
        </OutboundLink>
      );
    } else if (typeof window !== "undefined") {
      // either mailchimp button or link
      const storage = localStorage.getItem("user");
      let user = storage ? JSON.parse(storage) : null;
      const hasBoughtCourse = user ? user.hasBoughtCourse : null;
      if (hasBoughtCourse) {
        return <Link to={link.link}>{link.label}</Link>;
      } else {
        return <MailchimpPopup link={link}></MailchimpPopup>;
      }
    }
  }

  render() {
    return (
      <Layout>
        <SEO
          title={this.title}
          keywords={this.keywords}
          description={this.excerpt}
          url={this.url}
        />
        <PromotionPopup />
        {/* Course info */}
        <div className="container-fluid bg-white">
          <div className="container margin-from-header pt-1 pt-md-5 pb-6">
            <div className="row d-flex justify-content-md-center reverse-flex-mobile mt-2">
              <div className="col-sm-12 col-md-5 d-flex flex-column justify-content-center">
                <h2>{this.title}</h2>
                <p>{this.excerpt}</p>
                <div className="row pl-1 mt-1 d-flex align-items-center">
                  <React.Fragment>
                    {this.purchaseLinks.map((link, i) => {
                      if (i === 0) {
                        return (
                          <button className="btn-simple btn-primary-outline mt-2">
                            <div>{this.buttonForCourse(link)}</div>
                          </button>
                        );
                      } else {
                        // return a different button for the others
                        return (
                          <button className="btn mt-2 btn-outline">
                            {link.isOutbound ? (
                              <OutboundLink target="_blank" href={link.link}>
                                {link.label}
                              </OutboundLink>
                            ) : (
                              <Link to={link.link}>{link.label}</Link>
                            )}
                          </button>
                        );
                      }
                    })}
                  </React.Fragment>
                  <h1 className="text-success mt-2 mb-0 ml-2">
                    {this.price.toUpperCase()}
                  </h1>
                </div>
              </div>
              <div className={this.imageContainerClass}>
                {this.type === "course" ? (
                  <Img
                    fluid={this.course.image.childImageSharp.fluid}
                    alt={this.course.title}
                  />
                ) : (
                  <img src={this.shopItem ? this.shopItem.image : null} />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="container margin-from-header pb-6">
          <div className="row mb-6 justify-content-md-center">
            <div className="col-sm-12 col-md-8 mt-4 mt-md-0">
              <h2>What will you achieve?</h2>
              <ul>
                {this.extraInfo.map((info) => {
                  return <li>{info}</li>;
                })}
                {/* map over list */}
              </ul>

              {/* COURSE TABLE OF CONTENT */}
              {this.type === "course" ? (
                <React.Fragment>
                  <h2 className="pt-6">Table of Contents</h2>
                  <ol className="list-group">
                    {this.headings.map((heading, i) => {
                      return (
                        <li key={i} className="list-group-item ml-0">
                          Lesson {(i += 1)}: {heading.value}
                        </li>
                      );
                    })}
                  </ol>
                </React.Fragment>
              ) : null}

              {/* BOOK IMAGES */}

              {this.type === "book" ? (
                <React.Fragment>
                  <h2 className="pt-4">Book Preview</h2>
                  <ReactPhotoCollage {...this.setting} />
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default CourseDetails;

export const query = graphql`
  query ($coursePath: String, $detailPagePath: String) {
    allMdx(filter: { frontmatter: { path: { eq: $coursePath } } }) {
      edges {
        node {
          headings {
            depth
            value
          }
          id
          body
          frontmatter {
            title
            date
            path
            category
            excerpt
            image {
              childImageSharp {
                fluid(maxWidth: 900) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    allShopJson(filter: { detailPagePath: { eq: $detailPagePath } }) {
      edges {
        node {
          title
          description
          image
          detailPagePath
          price
        }
      }
    }
  }
`;
